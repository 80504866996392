<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BUSINESS PERMIT MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="year"
            item-text="year"
            label="Year Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <h2>Total P {{total_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>Released P {{released_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>COH P {{coh_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>RF P {{rf_amount}}</h2>
            <v-divider class="mx-4" inset vertical></v-divider>
            <h2>Liquidated P {{liquidated_amount}}</h2>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id
              }}
            </td>
            <td>
              {{ item.request_id
              }}
            </td>
            <td>
              {{
              (item.cv!=null?'CV#'+item.cv.voucher_no:(item.ckv!=null?'CKV#'+item.ckv.voucher_no:(item.ftv!=null?'FTV#'+item.ftv.voucher_no:'')))
              }}
            </td>
            <td>
              {{ formatPrice(item.amount)
              }}
            </td>
            <td>
              {{ item.credit_to
              }}
            </td>
            <td>
              {{ item.description
              }}
            </td>
            <td>
              <v-chip v-if="item.is_liquidated===1" color="success">
                LIQUIDATED
              </v-chip>
              <v-chip v-if="item.is_liquidated===0" color="error">
                UNLIQUIDATED
              </v-chip>
            </td>
            <td>
              <v-icon
                v-if="!is_viewing"
                class="mr-2"
                color="info"
                @click="view_liquidations(item)"
              >
                {{icons.mdiFileFind }}
              </v-icon>
              <v-progress-circular color="info" indeterminate
                                   v-else></v-progress-circular>
            </td>
            <td>
              <v-icon
                v-if="!is_viewing"
                class="mr-2"
                color="warning"
                @click="view_released(item)"
              >
                {{icons.mdiFileFind }}
              </v-icon>
              <v-progress-circular color="warning" indeterminate
                                   v-else></v-progress-circular>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog :key="this.key" v-model="is_view_liquidation" persistent width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h5 class="font-weight-light">LIQUIDATIONS DATA</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-row dense class="mt-3">
          <v-col md="12" cols="12">
            <v-data-table
              dense
              :headers="headers2"
              :items="liquidated_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <h2>Total P {{total_amount2}}</h2>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id
                    }}
                  </td>
                  <td>
                    {{ item.branch_code
                    }}
                  </td>
                  <td>
                    {{ item.particulars
                    }}
                  </td>
                  <td>
                    {{ item.date
                    }}
                  </td>
                  <td>
                    {{ item.amount
                    }}
                  </td>
                  <td v-if="position==='ADMIN'||position==='ACCOUNTING'">

                    <div v-if="item.is_liquidated===0">
                      <v-icon
                        v-if="!is_deleting"
                        class="mr-2"
                        color="info"
                        @click="request_to_liquidated(item,'liquidation')"
                      >
                        {{icons.mdiCheckDecagram}}
                      </v-icon>
                      <v-progress-circular color="info" indeterminate
                                           v-if="is_deleting"></v-progress-circular>
                    </div>
                    <v-chip v-if="item.is_liquidated===1">
                      LIQUIDATED
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="mt-4">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h5 class="font-weight-light">RETURN FUND DATA</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-row dense class="mt-3">
          <v-col md="12" cols="12">
            <v-data-table
              dense
              :headers="headers3"
              :items="ret_liquidated_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <h2>Total P {{total_amount3}}</h2>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id
                    }}
                  </td>
                  <td>
                    {{ item.branch_code
                    }}
                  </td>
                  <td>
                    {{ item.bank_code
                    }}
                  </td>
                  <td>
                    {{ item.date
                    }}
                  </td>
                  <td>
                    {{ item.amount
                    }}
                  </td>
                  <td v-if="position==='ADMIN'||position==='ACCOUNTING'">

                    <div v-if="item.is_liquidated===0">
                      <v-icon
                        v-if="!is_deleting"
                        class="mr-2"
                        color="info"
                        @click="request_to_liquidated(item,'return')"
                      >
                        {{icons.mdiCheckDecagram}}
                      </v-icon>
                      <v-progress-circular color="info" indeterminate
                                           v-if="is_deleting"></v-progress-circular>
                    </div>
                    <v-chip v-if="item.is_liquidated===1">
                      LIQUIDATED
                    </v-chip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-btn color="error" @click="close_dialog"> Close</v-btn>
    </v-dialog>
    <v-dialog :key="this.key" v-model="is_view_released" persistent width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title
          ><h5 class="font-weight-light">RELEASED DATA</h5>
          </v-toolbar-title>
        </v-toolbar>
        <v-row dense class="mt-3">
          <v-col md="12" cols="12">
            <v-data-table
              dense
              :headers="headers_released"
              :items="released_items"
              height="600"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <h2>Total P {{total_amount_released}}</h2>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id
                    }}
                  </td>
                  <td>
                    {{ item.branch_code
                    }}
                  </td>
                  <td>
                    {{ item.date
                    }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount)
                    }}
                  </td>

                  <td>
                    {{ item.credit_to
                    }}
                  </td>
                  <td>
                    {{ item.fund_counter
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
      <v-btn color="error" @click="close_dialog"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountCashOutline,
    mdiAccountOutline,
    mdiAccountSearch,
    mdiCheckDecagram,
    mdiFileFind,
    mdiInformationOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      is_deleting: false,
      is_viewing: false,
      key: 0,
      is_view_liquidation: false,
      is_view_released: false,
      liquidated_amount: '0.00',
      total_amount2: '0.00',
      total_amount_released: '0.00',
      rf_amount: '0.00',
      coh_amount: '0.00',
      released_amount: '0.00',
      total_amount: '0.00',
      year: '',
      year_items: [],
      liquidated_items: [],
      ret_liquidated_items: [],
      data_items: [],
      released_items: [],
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'date_of_deposit', sortable: false},
        {text: 'Voucher #', value: 'tin', sortable: false},
        {text: 'Amount', value: 'employee.last_name', sortable: false},
        {text: 'Credit To', value: 'amount', sortable: false},
        {text: 'Description', value: 'bank_code_name', sortable: false},
        {text: 'Status', value: 'bank_code_name', sortable: false},
        {text: 'View Liquidation', value: 'bank_code_name', sortable: false},
        {text: 'View Released Fund', value: 'bank_code_name', sortable: false},
      ],
      headers2: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Branch', value: 'branch_code', sortable: false},
        {text: 'Particulars', value: 'particulars', sortable: false},
        {text: 'Date', value: 'date', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
        {text: 'Status', value: 'is_liquidated', sortable: false},
      ],
      headers_released: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Branch', value: 'branch_code', sortable: false},
        {text: 'Date', value: 'date', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
        {text: 'Credit To', value: 'credit_to', sortable: false},
        {text: 'Fund Counter', value: 'credit_to', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Branch', value: 'branch_code', sortable: false},
        {text: 'Bank Code', value: 'branch_code', sortable: false},
        {text: 'Date', value: 'particulars', sortable: false},
        {text: 'Amount', value: 'amount', sortable: false},
        {text: 'Status', value: 'is_liquidated', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
          mdiFileFind,
          mdiCheckDecagram,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position', 'employee_id']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee',]),
      ...mapActions('request_data', ['list_of_business_permit',]),
      ...mapActions('business_permit_return_fund', ['bpr_change_status',]),
      ...mapActions('business_permit_liquidation', ['bpl_change_status',]),
      ...mapActions('monthly_liquidation', [
        'register_regular_liquidation',
      ]),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('year', this.year);
        this.list_of_business_permit(data)
          .then(response => {
            this.data_items = response.data[0].credited
            this.liquidated_amount = response.data[0].liquidated_amount
            this.rf_amount = response.data[0].rf_amount
            this.coh_amount = response.data[0].coh_amount
            this.released_amount = response.data[0].released_amount
            this.total_amount = response.data[0].total_amount
          })
          .catch(error => {
            console.log(error)
          })
      },
      view_liquidations(item) {
        this.is_view_liquidation = true
        this.key++
        this.selected_fund = item
        var arra = []
        var arra2 = []
        var tot = 0
        var tot2 = 0
        item.business_permit_released.forEach(function (item2) {
          var branch_details = item2.branch
          item2.liquidated.forEach(function (item3) {
            tot += parseFloat(item3.amount)
            arra.push({
              id: item3.id,
              branch_id: branch_details.id,
              branch_code: branch_details.branch_code,
              particulars: item3.particulars,
              bank_id: 0,
              date: item3.date,
              amount: item3.amount,
              is_liquidated: item3.is_liquidated,
            })
          })
          item2.return_fund.forEach(function (item3) {
            tot2 += parseFloat(item3.amount)
            arra2.push({
              id: item3.id,
              branch_id: branch_details.id,
              branch_code: branch_details.branch_code,
              bank_id: item3.bank.id,
              bank_code: item3.bank.bank_code,
              date: item3.date,
              amount: item3.amount,
              is_liquidated: item3.is_liquidated,
            })
          })
        })
        this.total_amount2 = this.formatPrice(tot)
        this.total_amount3 = this.formatPrice(tot2)
        this.liquidated_items = arra
        this.ret_liquidated_items = arra2
      },
      view_released(item) {
        this.is_view_released = true
        this.key++
        this.selected_fund = item
        var arra = []
        var tot = 0
        item.business_permit_released.forEach(function (item2) {
          tot += parseFloat(item2.amount)
          var branch_details = item2.branch
          arra.push({
            id: item2.id,
            branch_id: branch_details.id,
            branch_code: branch_details.branch_code,
            date: item2.date,
            amount: item2.amount,
            credit_to: item2.credit_to,
            fund_counter: item2.fund_counter,
          })
        })
        this.total_amount_released = this.formatPrice(tot)
        this.released_items = arra
      },
      close_dialog() {
        this.is_view_liquidation = false
        this.is_view_released = false
        this.selected_month()
      },
      request_to_liquidated(item, from) {
        this.is_deleting = true
        const data = new FormData()
        data.append('cash_voucher_id', this.selected_fund.cv_id)
        data.append('category_id', this.selected_fund.category_id)
        data.append('month_of_id', this.selected_fund.cv.month_of_id)
        data.append('date', item.date)
        data.append('credit_to', this.selected_fund.credit_to)
        data.append('particulars', 'Business Permit')
        data.append('payment_for', 'Business Permit')
        data.append('amount', item.amount)
        data.append('description', from === 'return' ? this.selected_fund.description : item.particulars)
        data.append('branch_id', item.branch_id)
        data.append('request_data_id', this.selected_fund.id)
        data.append('is_return_fund', from === 'return' ? 1 : 0)
        data.append('bank_id', item.bank_id)
        data.append('date_deposited', item.bank_id > 0 ? item.date : null)
        data.append('time_deposited', null)
        data.append('deposit_status', from === 'return' ? 'Excess' : null)
        data.append('is_no_or', false)
        data.append('or_no', '')
        data.append('specify', '')
        data.append('employee_id', this.employee_id)
        this.register_regular_liquidation(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: 'SUCCESSFULLY LIQUIDATE',
            })
            if (response.status === 200) {
              if (from === 'return') {
                this.bpr_change_status({
                  id: item.id,
                  status: 1,
                })
                var indexx = this.ret_liquidated_items.findIndex((obj => obj.id === item.id));
                this.ret_liquidated_items[indexx].is_liquidated = 1
                this.is_deleting = false
              } else {
                this.bpl_change_status({
                  id: item.id,
                  status: 1,
                })
                var indexx = this.liquidated_items.findIndex((obj => obj.id === item.id));
                this.liquidated_items[indexx].is_liquidated = 1
                this.is_deleting = false
              }
            }
            this.selected_month()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving_data = false
          })
      },
    },
  }
</script>
